import "./Footer.css";
import { FiFacebook } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { BsTwitterX } from "react-icons/bs";

import Logo from "../../photos/PROKE-LOGO/full-logo/digital-rgb/PROKER-RGB-svg 1.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export default function Footer() {
  const { t } = useTranslation();
  const generateWhatsAppLink = () => {
    
    const phoneNumber = '01098837767';
   
    const message = 'اريد الأستفسار عن  ';
    const countryCode = '+2';
    return `https://wa.me/${countryCode}${phoneNumber}?text=${encodeURIComponent(message)}`;
  };
  return (
    <>
      <section className="sec8_bg h-100 pb-3">
        <div className="m-78">
          <div className="row  container-fluid justify-content-center  ">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-12 text-white mt-5 h4_footer_media text_center_media">
              <div className="">
                <img src={Logo} alt="" className="logo_footer" />
              </div>
              <p className="">
                {t(
                  "The company has contributed to the success of many projects"
                )}
                <br />
                {t("and Commercial centers throughout the Republic")}
                <br />
                {t(
                  "and Ensuring providing integrated and innovative real estate solutions."
                )}
              </p>
              <div className="mt-4">
                <Link to={"https://www.facebook.com/profile.php?id=61555939085643"} className="text-decoration-none"><FiFacebook className="fs-2 me-4 footer_Links" /></Link>
                <Link to={"https://www.instagram.com/prokercompany/"} className="text-decoration-none"> <FaInstagram className="fs-2 me-4 footer_Links" /></Link>
                <Link to={""} className="text-decoration-none"><BsTwitterX className="fs-2 me-4 footer_Links"/></Link>
              </div>
            </div>
            <div className=" offset-1 col-xxl-2 col-xl-2 col-lg-2 col-12 mt-5 text-white mx-auto h4_footer_media text_center_media">
              <h3 className="mt-5 ">{t("Links")}</h3>
              <Link onClick={() => window.scrollTo(0, 0)}  className="text-decoration-none" to={"/"}><h4 className=" mt-4 footer_Links ">{t("Home")}</h4></Link>
              <Link onClick={() => window.scrollTo(0, 0)}  className="text-decoration-none" to={"/properities/Buy"}><h4 className=" mt-3 footer_Links">{t("Properties")}</h4></Link>
              <Link onClick={() => window.scrollTo(0, 0)}  className="text-decoration-none" to={"/AboutUs"}> <h4 className=" mt-3 footer_Links">{t("About")}</h4></Link>
              <Link onClick={() => window.scrollTo(0, 0)}  className="text-decoration-none"  to={"/Services"}><h4 className=" mt-3 footer_Links" >{t("Services")}</h4></Link>
              <Link onClick={() => window.scrollTo(0, 0)}  className="text-decoration-none" to={"/ContactUs"}> <h4 className=" mt-3 footer_Links">{t("Contact US")}</h4></Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-12 mt-5 text-white h4_footer_media text_center_media">
              <h3 className="mt-5">{t("Contact Info")}</h3>
              <Link to={generateWhatsAppLink()} className="text-decoration-none"><h4 className="mt-5 footer_Links">
                <BsTelephone className="me-3 " />{t("+ 01098837767")}
              </h4>
              </Link>
              <Link to={"https://mail.google.com/mail/?view=cm&fs=1&to=prokercompany@gmail.com"} className="text-decoration-none"><h4 className="mt-5  footer_Links">
                <CiMail className="me-3 " />
                {t("prokercompany@gmail.com")}
              </h4>
              </Link>
              <h4 className="mt-5 me-3 footer_Links text_center_media">{t("commercial register:53077")}</h4>
              <h4 className="mt-5 me-3 footer_Links text_center_media">{t("Tax Number:498787885")}</h4>
              {/* <Link to={"https://www.google.com/maps/place/30%C2%B048'09.0%22N+30%C2%B059'46.7%22E/@30.8024897,30.9956515,19z/data=!3m1!4b1!4m4!3m3!8m2!3d30.8024885!4d30.9962952?entry=ttu"} className="text-decoration-none"> <h4 className="mt-5 footer_Links">
                <CiLocationOn className="me-3 " />
                {t("Elbaher street")}
              </h4>
              </Link> */}
            </div>
          <div className="footer_line"> </div>
            <div className="mt-2 "> 

            <Link onClick={()=>{window.scroll(0,0)}} className="text-white pt-3 fs-5 cobyright_underline h4_footer_media">
              <h4>{t("copyright © 2024 proker Company")}</h4>
            </Link>

            </div>
          
          </div>
        </div>
      </section>
    </>
  );
}
