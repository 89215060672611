import { Link, Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import axios from "axios";
import Sell from "../pages/Sell/Sell";
import AboutUs from "../pages/AboutUS/AboutUs";
import Services from "../pages/Services/Services";
import Buy from "../pages/Buy/Buy";
import ContactUs from "../pages/ContactUs/ContactUs";
import Search from "../pages/Search/Search";
import Demand from "../pages/Demand/Demand";
import Details from "../pages/Details/Details";
import { useContext } from "react";
import { UserContext } from "../UserContext";
import { SiWhatsapp } from "react-icons/si";
function MainRoutes() {
  axios.defaults.baseURL = "https://backend.prokercompany.com";
  const [user] = useContext(UserContext);
  const generateWhatsAppLink = () => {
    // Replace "1234567890" with your WhatsApp number
    const phoneNumber = '01098837767';
    // Replace "Hello!" with your pre-filled message
    const message = 'اريد الأستفسار عن  ';
    const countryCode = '+2';
    return `https://wa.me/${countryCode}${phoneNumber}?text=${encodeURIComponent(message)}`;
  };
  return (
    <>
      <section    className={user === "ar" ? " floating_whatssapp_ar " : " floating_whatssapp "} >
<Link to={generateWhatsAppLink()} target="_blank" rel="noopener noreferrer"><SiWhatsapp className=" whatssapp_icon" /></Link>

</section>
      <Routes>
      
        <Route path="/" exact element={<Home />} />
        <Route path="/properities/Sell" exact element={<Sell />} />
        <Route path="/AboutUs" exact element={<AboutUs />} />
        <Route path="/Services" exact element={<Services />} />
        <Route path="/properities/Buy" exact element={<Buy />} />
        <Route
          path="/properities/Search/*"
          exact
          element={<Search />}
        />

        <Route path="/Details/:ID" exact element={<Details />} />
        <Route path="/properities/Demand" exact element={<Demand />} />
        <Route path="/ContactUs" exact element={<ContactUs />} />
      </Routes>
    </>
  );
}

export default MainRoutes;
