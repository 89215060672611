import NavBar from "../../component/Navebar/Navbar";
import "./Details.css";
import Footer from "../../component/footer/Footer";
import Modal from "react-modal";
import { useSwipeable } from 'react-swipeable';
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineOtherHouses } from "react-icons/md";
import { MdOutlineKitchen } from "react-icons/md";
import { MdOutlineBedroomParent } from "react-icons/md";
import { GiHomeGarage } from "react-icons/gi";
import { MdOutlineBathroom } from "react-icons/md";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BsHouseUp } from "react-icons/bs";
import { FaSwimmingPool } from "react-icons/fa";
import { MdApartment } from "react-icons/md";
import { useTranslation } from "react-i18next";
import {
  AxiosGetAllByID,
  AxiosGetRecomende,
} from "../../component/axios/GetApi/GetApi";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { UseAxiosPostTour } from "../../component/axios/PostApi/PostApi";
Modal.setAppElement("#root");
export default function Details() {
  const { ID } = useParams();
  const [Tour, setTour] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });

  function getTourinfo(e) {
    let Tourinfo = { ...Tour };
    Tourinfo[e.target.name] = e.target.value;
    setTour(Tourinfo);
  }
  const { data, getAllDataByID } = AxiosGetAllByID();
  let DataByID = data ? data.data : [];
  let ImgsByID = data ? data.data.images : [];
  const [isOpen, setIsOpen] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState(0);

  const openModal = (index) => {
    setCurrentPhoto(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowRight") {
      setCurrentPhoto((prevPhoto) => (prevPhoto + 1) % ImgsByID.length);
    } else if (e.key === "ArrowLeft") {
      setCurrentPhoto(
        (prevPhoto) => (prevPhoto - 1 + ImgsByID.length) % ImgsByID.length
      );
    } else if (e.key === "Escape") {
      closeModal();
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrentPhoto((prevPhoto) => (prevPhoto + 1) % ImgsByID.length),
    onSwipedRight: () => setCurrentPhoto((prevPhoto) => (prevPhoto - 1 + ImgsByID.length) % ImgsByID.length),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  const { data: Datarecomend, GetRecomende } = AxiosGetRecomende();
  let datarecomend = DataByID && Datarecomend ? Datarecomend.data : [];
  const { HandelPostApi } = UseAxiosPostTour(Tour);
  const itemsubmit = (e) => {
    e.preventDefault();
    HandelPostApi(ID, DataByID.title);
  };
  const { t } = useTranslation();
  useEffect(() => {
    getAllDataByID(ID);
    GetRecomende(
      DataByID._id,
      DataByID.location,
      DataByID.price,
      DataByID.propertyType
    );
  }, [ID]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const cols = isSmallScreen ? 1 : 3;

  return (
    <>
      <section>
        <NavBar />
      </section>

      <section className="m-78 pt-4 pb-4 mt-5">
        <div className="container-fluid  pt-5">
          <div className="row details_media_center">
            <div className="col-xxl-7 col-xl-7  col-12 mb-5 ">
              <div>
                <h2>{DataByID.title}</h2>
                <h6>
                  <CiLocationOn className="me-2" />
                  {t(`${DataByID.location}`)}
                </h6>
              </div>
              <div className="">
                <ImageList cols={cols} rowHeight={160} >
                  {ImgsByID.map((item, index) => (
                    <ImageListItem key={index} className="img_list">
                      <img
                        src={item}
                        alt=""
                        onClick={() => openModal(index)}
                        className="thumbnail"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>

                <Modal
                  isOpen={isOpen}
                  onRequestClose={closeModal}
                  onAfterOpen={() =>
                    window.addEventListener("keydown", handleKeyDown)
                  }
                  onAfterClose={() =>
                    window.removeEventListener("keydown", handleKeyDown)
                  }
                  className="modal"
                  overlayClassName="overlay"
                >
                   <div className="modal-content" onClick={closeModal}   {...handlers}>
          <img src={ImgsByID[currentPhoto]} alt="" className="modal-photo" onClick={(e) => e.stopPropagation()} />
        </div>
                </Modal>
              </div>
              <div>
                <h2>{t("Property Description")}</h2>
                <h6>{DataByID.description}</h6>
                <h2 className="mt-5">{t("Property Features")}</h2>
                <div className="row ">
                  <div className="col-12 ">
                    <h5>
                      <MdOutlineOtherHouses className="me-3 " />
                      {t("Building Size")} {DataByID.building_size} m
                    </h5>
                  </div>
                  <div className="col-12">
                    <h5>
                      <MdOutlineKitchen className="me-3" />
                      {DataByID.kitchen_number} {t("Large Kitchen")}
                    </h5>
                  </div>
                  <div className="col-12">
                    <h5>
                      <MdOutlineBedroomParent className="me-3" />
                      {DataByID.bedroom_number} {t("Bedroom")}
                    </h5>
                  </div>
                  <div className="col-12">
                    <h5>
                      <GiHomeGarage className="me-3" />
                      {DataByID.carageSpaces_number} {t("Garage Spaces")}
                    </h5>
                  </div>
                  {DataByID.bathroom_number ? (
                    <div className="col-12">
                      <h5>
                        <MdOutlineBathroom className="me-3" />
                        {DataByID.bathroom_number} {t("Bathroom")}
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {DataByID.pool_number ? (
                    <div className="col-12">
                      <h5>
                        <FaSwimmingPool className="me-3" />
                        {DataByID.pool_number} {t("Pool")}
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {DataByID.propertyType == "House" ? (
                    <div className="col-12">
                      <h5>
                        <MdApartment className="me-3" />
                        {DataByID.apartments_number} {t("Apartments")}
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {DataByID.propertyType == "House" ? (
                    <div className="col-12">
                      <h5>
                        <BsHouseUp className="me-3" />
                        {DataByID.floors_number} {t("Floors")}
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}

                  <h2 className="mt-5">{t("location")}</h2>
                  <h5>
                    <CiLocationOn className="me-2" />
                    {t(`${DataByID.location}`)}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5  col-lg-12 col-12">
              <h2 className="">
                {DataByID.price} {t("L.E")}
              </h2>
              <h6 className="">
                {t(`${DataByID.propertyType}`)} {t("For Sell")}
              </h6>
              <div className="mt-4 Details_requist mx-auto">
                <form onSubmit={itemsubmit} action="">
                  <h6 className="mt-3 ps-3">{t("Request a Showing Tour")}</h6>
                  <div className="text-center">
                    <input
                      type="text"
                      onChange={getTourinfo}
                      name="name"
                      placeholder={t("Name")}
                      className=" ps-3 mt-3 "
                    />
                    <input
                      type="text"
                      onChange={getTourinfo}
                      name="phoneNumber"
                      placeholder={t("Phone Number")}
                      className=" mt-4 ps-3  "
                    />
                    <input
                      type="text"
                      onChange={getTourinfo}
                      name="email"
                      placeholder={t("Email")}
                      className=" mt-4 ps-3 "
                    />
                  </div>
                  <div className="text-center mt-4">
                    <button type="submit" className="Details_request_btn ">
                      {t("Request ➢")}
                    </button>
                  </div>
                </form>
              </div>
              <div className="mt-4">
                <h3> {t("Similar Properties")}</h3>
                <div className="row justify-content-center mt-5 Details_card">
                  {datarecomend.slice(0, 4).map((item, index) => (
                    <div
                      key={index}
                      className="col-xxl-6 col-xl-6 col-lg-6 col-12  mb-4"
                    >
                      <div className="card mx-auto">
                        <img
                          src={item.coverImage}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body pb-0">
                          <h5 className="card-title">{item.title}</h5>
                          <div className="row mt-4">
                            <div className="col-7">
                              <Link
                                to={`/Details/${item._id}`}
                                className="card-text text-decoration-none sec_card_text "
                              >
                                <p>{t("View Details →")}</p>
                              </Link>
                            </div>
                            <div className="col-5">
                              <p>
                                {item.price} {t("L.E")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </>
  );
}
