import "./Home.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useRef, useState } from "react";
import { IoMegaphoneOutline } from "react-icons/io5";
import { TbBusinessplan } from "react-icons/tb";
import { TbServerCog } from "react-icons/tb";
import { TbHomeStats } from "react-icons/tb";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineHouse } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { TbHomeSearch } from "react-icons/tb";
import sec4_img from "../../photos/Group 10.png";
import sec6_img from "../../photos/Group 18 (1).png";
import NavbarHome from "../../component/Navebar/NavbarHome";
import { Link } from "react-router-dom";
import Footer from "../../component/footer/Footer";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../UserContext";
import { useContext } from "react";
import { UseAxiosPostContactUs } from "../../component/axios/PostApi/PostApi";
import {
  AxiosGetAllByDeveloper,
  AxiosGetAllByType,
} from "../../component/axios/GetApi/GetApi";
import NavBar from "../../component/Navebar/Navbar";
export default function Home() {
  const { t } = useTranslation();
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);

  const scrollToSection = (sectionRef) => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  let [PropertyType, setPropertyType] = useState("house");
  let [PropertyInvestor, setPropertyInvestor] = useState("");
  const { data, GetAllByType } = AxiosGetAllByType();
  let DataByType = data ? data.data : [];
  const { data: investorData, GetAllByDeveloper } = AxiosGetAllByDeveloper();
  let DataByInvestor = investorData ? investorData.data : [];
  const [Search, setSearch] = useState({
    investor: "",
    Location: "",
    Type: "",
    Maxprice: "",
  });
  function getSearchinfo(e) {
    let myitem = { ...Search };
    myitem[e.target.name] = e.target.value;
    setSearch(myitem);
  }

  const [ContactUs, setContactUs] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    message: "",
  });
  function getContactinfo(e) {
    let contactinfo = { ...ContactUs };
    contactinfo[e.target.name] = e.target.value;
    setContactUs(contactinfo);
  }
  const { HandelPostApi } = UseAxiosPostContactUs(ContactUs);
  const itemsubmit = (e) => {
    e.preventDefault();
    HandelPostApi();
  };
  const [user] = useContext(UserContext);
  const isSearchFilled = Object.values(Search).every(
    (value) => value.trim() !== ""
  );
  const [scrolledDown, setScrolledDown] = useState(false);
  useEffect(() => {
    GetAllByType(PropertyType, "1");
    GetAllByDeveloper(PropertyInvestor);
    const handleScroll = () => {
      if (window.scrollY > 2) {
        setScrolledDown(true);
      } else {
        setScrolledDown(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [PropertyType, PropertyInvestor]);
  return (
    <>
      {scrolledDown ? <NavBar /> : null}
      <section className={user === "ar" ? " sec1_bg_ar " : " sec1_bg "}>
        <div
          className={
            user === "ar"
              ? "container-fluid sec1_bg2_ar  "
              : " container-fluid sec1_bg2 "
          }
        >
          {scrolledDown ? null : <NavbarHome />}
          <div className=" text-white   d-flex align-items-center h-100 sec1_home_text_center ">
            <div className="">
              <h1>
                {t("We help you choose")} <br />
              </h1>
              <h5 className="mt-3">
                {t("The best real estate units with high investment returns")}
                <br />
                {t("In various parts of the Arab Republic of Egypt.")}
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className=" container-fluid  ">
        <div className="sec2-bg">
          <div className="row justify-content-center  ">
            <div className="col-7 sec2-bg2 pb-4 ">
              <div className="sec2_home_ms">
                <h5 className="mt-4 ">
                  {t("Search For Available properties")}
                </h5>
                <div className="row mt-4 mx-auto  ">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-12 mb-3 ">
                    <div className="input-group position-relative">
                         <select
                        className="form-control py-2 border-end-0 border"
                        aria-label="Default select example"
                        id="example-search-input"
                        onChange={getSearchinfo}
                        name="investor"
                      >
                        <option value="" selected>
                          {t("developer")}
                        </option>
                        <option value="Alashraf">{t("Alashraf")}</option>
                        <option value="Almarasem">{t("Almarasem")}</option>
                        <option value="TE">{t("TE")}</option>
                        <option value="Frimo">{t("Frimo")}</option>
                        <option value="Efid">{t("Efid")}</option>
                        <option value="Soliman">{t("Soliman")}</option>
                        

                      </select>
                      <span
                        className={
                          user === "ar"
                            ? "input-group-append_ar "
                            : "input-group-append"
                        }
                      >
                        <i>
                          <TbBusinessplan className=" fs-5" />
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-12 mb-3 ">
                    <div className="input-group position-relative">
                        <select
                        className="form-control py-2 border-end-0 border"
                        aria-label="Default select example"
                        id="example-search-input"
                        onChange={getSearchinfo}
                        name="Location"
                      >
                        <option value="" selected>
                          {t("Location")}
                        </option>
                        <option value="Zayed">{t("Zayed")}</option>
                        <option value="Zaied new">{t("Zaied new")}</option>
                        <option value="October new">{t("October new")}</option>
                        <option value="October">{t("October")}</option>
                        <option value="el tagamoa el khames">{t("el tagamoa el khames")}</option>
                        <option value="new capital">{t("new capital")}</option>
                        <option value="Ras El Hekma">{t("Ras El Hekma")}</option>

                      </select>
                      <span
                        className={
                          user === "ar"
                            ? "input-group-append_ar "
                            : "input-group-append"
                        }
                      >
                        <i>
                          <IoLocationOutline className=" fs-5" />
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-12 mb-3">
                    <div className="input-group position-relative">
                      <select
                        className="form-control py-2 border-end-0 border"
                        aria-label="Default select example"
                        id="example-search-input"
                        onChange={getSearchinfo}
                        name="Type"
                      >
                        <option value="" selected>
                          {t("Property Type")}
                        </option>
                        <option value="House">{t("Building")}</option>
                        <option value="Vila">{t("Vila")}</option>
                        <option value="Apartment">{t("Apartments")}</option>
                        <option value="Office">{t("Office")}</option>
                        <option value="Shop">{t("Shop")}</option>
                        <option value="Coastal">{t("Coastal")}</option>
                      </select>
                      {/* <input
                      className="form-control py-2 border-end-0 border "
                      type="search"
                      onChange={getSearchinfo}
                      name="Type"
                      placeholder={t("Property Type")}
                      id="example-search-input"
                    /> */}
                      <span
                        className={
                          user === "ar"
                            ? "input-group-append_ar "
                            : "input-group-append"
                        }
                      >
                        <i>
                          <MdOutlineHouse className=" fs-5" />
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-12 mb-3">
                    <div className="input-group position-relative">
                      <input
                        className="form-control py-2 border-end-0 border "
                        type="search"
                        onChange={getSearchinfo}
                        name="Maxprice"
                        placeholder={t("Budget")}
                        id="example-search-input"
                      />
                      <span
                        className={
                          user === "ar"
                            ? "input-group-append_ar "
                            : "input-group-append"
                        }
                      >
                        <i>
                          <GiCash className=" fs-5" />
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className=" col-12 ps-4 d-flex justify-content-end">
                    <Link
                      to={`/properities/Search/${Search.investor}/${Search.Location}/${Search.Type}/${Search.Maxprice}`}
                      className="text-white text-decoration-none "
                    >
                      <span className="sec2_search px-2  ">
                        <TbHomeSearch className="mb-2" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid mt-5 mb-5">
        <div className=" m-78">
          <h1 className="">{t("Unit types")}</h1>
          <div className=" row mt-4 m-auto d-flex justify-content-center">
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-12  mt-2   d-flex justify-content-center">
              <Link
                onClick={() => {
                  setPropertyType("house");
                  scrollToSection(section1Ref);
                }}
                className="sec3_filtration text-decoration-none "
              >
                <div className="New_Projects_btn me-2 ">
                  <button className="btn-sec3">{t("New Projects")}</button>
                </div>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-12 mt-2  d-flex justify-content-center">
              <Link
                onClick={() => {
                  setPropertyType("Vila");
                  scrollToSection(section1Ref);
                }}
                className=" text-decoration-none sec3_filtration"
              >
                <div className="vila_btn me-2 ">
                  <button className="btn-sec3">{t("Vila")}</button>
                </div>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-12 mt-2  d-flex justify-content-center">
              <Link
                onClick={() => {
                  setPropertyType("Apartment");
                  scrollToSection(section1Ref);
                }}
                className=" text-decoration-none sec3_filtration"
              >
                <div className="apartment_btn me-2 ">
                  <button className="btn-sec3">{t("Apartments")}</button>
                </div>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-12 mt-2 d-flex justify-content-center ">
              <Link
                onClick={() => {
                  setPropertyType("Office");
                  scrollToSection(section1Ref);
                }}
                className=" text-decoration-none sec3_filtration"
              >
                <div className="office_btn me-2 ">
                  <button className="btn-sec3">{t("Office")}</button>
                </div>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-12 mt-2 d-flex justify-content-center">
              <Link
                onClick={() => {
                  setPropertyType("Shop");
                  scrollToSection(section1Ref);
                }}
                className=" text-decoration-none sec3_filtration"
              >
                <div className="shope_btn me-2 ">
                  <button className="btn-sec3">{t("Shop")}</button>
                </div>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-12 mt-2 d-flex justify-content-center">
              <Link
                onClick={() => {
                  setPropertyType("Coastal");
                  scrollToSection(section1Ref);
                }}
                className=" text-decoration-none sec3_filtration"
              >
                <div className="coastal_btn me-2 ">
                  <button className="btn-sec3">{t("Coastal")}</button>
                </div>
              </Link>
            </div>
          </div>
          <div
            ref={section1Ref}
            className="row justify-content-center home_sell_card"
          >
            {DataByType.slice(0, 4).map((data, index) => (
              <div
                key={index}
                className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-6 mb-4 "
              >
                <div className="card mx-auto">
                  <Link to={`/Details/${data._id}`} onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                    <img src={data.coverImage} className="img_card" alt="..." />
                  </Link>
                  <div className="card-body pb-0">
                    <h5 className="card-title">{data.title}</h5>
                    <div className="row mt-4">
                      <div className=" col-12 text_center_media">
                        <p>
                          {t("Price: ")}
                          {data.price} {t("L.E")}
                        </p>
                      </div>
                      <div className=" text-center">
                        <Link
                          to={`/Details/${data._id}`}
                          onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' })}}
                          className="card-text text-decoration-none sec_card_text  "
                        >
                          <p>{t("View Details →")}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          

          </div>
        </div>
      </section>
      <section className="container-fluid mt-5   mb-5">
        <div className=" m-78">
          <h1 className="">{t("Real estate developers")}</h1>
          <div className=" row mt-4 justify-content-center ">
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-6 mt-2 ">
              <Link
                onClick={() => {
                  setPropertyInvestor("Efid");
                  scrollToSection(section2Ref);
                }}
                className="sec3_filtration text-decoration-none "
              >
                <div className="Efid_btn me-2 "></div>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-6 mt-2 ">
              <Link
                onClick={() => {
                  setPropertyInvestor("Soliman");
                  scrollToSection(section2Ref);
                }}
                className=" text-decoration-none sec3_filtration"
              >
                <div className="Soliman_btn me-2 "></div>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-6 mt-2 ">
              <Link
                onClick={() => {
                  setPropertyInvestor("TE");
                  scrollToSection(section2Ref);
                }}
                className=" text-decoration-none sec3_filtration"
              >
                <div className="TE_btn me-2"></div>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-6 mt-2 ">
              <Link
                onClick={() => {
                  setPropertyInvestor("Almarasem");
                  scrollToSection(section2Ref);
                }}
                className=" text-decoration-none sec3_filtration"
              >
                <div className="Almarasem_btn me-2"></div>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-6 mt-2 ">
              <Link
                onClick={() => {
                  setPropertyInvestor("Frimo");
                  scrollToSection(section2Ref);
                }}
                className=" text-decoration-none sec3_filtration"
              >
                <div className="Frimo_btn me-2"></div>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-6 mt-2 ">
              <Link
                onClick={() => {
                  setPropertyInvestor("Alashraf");
                  scrollToSection(section2Ref);
                }}
                className=" text-decoration-none sec3_filtration"
              >
                <div className="Alashraf_btn me-2"></div>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-6 mt-2 ">
              <Link
                onClick={() => {
                  setPropertyInvestor("Alashraf");
                  scrollToSection(section2Ref);
                }}
                className=" text-decoration-none sec3_filtration"
              >
                <div className="Alashraf_btn me-2"></div>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-6 mt-2 ">
              <Link
                onClick={() => {
                  setPropertyInvestor("Alashraf");
                  scrollToSection(section2Ref);
                }}
                className=" text-decoration-none sec3_filtration"
              >
                <div className="Alashraf_btn me-2"></div>
              </Link>
            </div>
          </div>
          <div
            ref={section2Ref}
            className="row justify-content-center mt-5 home_sell_card"
          >
            {DataByInvestor.slice(0, 4).map((data, index) => (
          <div
          key={index}
          className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-6 mb-4 "
        >
          <div className="card mx-auto">
            <Link to={`/Details/${data._id}`} onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' })}}>
              <img src={data.coverImage} className="img_card" alt="..." />
            </Link>
            <div className="card-body pb-0">
              <h5 className="card-title">{data.title}</h5>
              <div className="row mt-4">
                <div className=" col-12 text_center_media">
                  <p>
                    {t("Price: ")}
                    {data.price} {t("L.E")}
                  </p>
                </div>
                <div className=" text-center">
                  <Link
                    to={`/Details/${data._id}`}
                    onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' })}}
                    className="card-text text-decoration-none sec_card_text  "
                  >
                    <p>{t("View Details →")}</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
            ))}
            {/* <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-6 mb-4 ">
              <div className="card mx-auto">
                <img src={img_test} className="card-img-top" alt="..." />
                <div className="card-body pb-0">
                  <h5 className="card-title">Sunrise Apartment</h5>
                  <div className="row mt-4">
                    <div className="col-7">
                      <Link className="card-text text-decoration-none sec_card_text ">
                        <p>View Details 🡪</p>
                      </Link>
                    </div>
                    <div className="col-5">
                      <p>700.000 L.E</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-6 mb-4 ">
              <div className="card mx-auto">
                <img src={img_test} className="card-img-top" alt="..." />
                <div className="card-body pb-0">
                  <h5 className="card-title">Sunrise Apartment</h5>
                  <div className="row mt-4">
                    <div className="col-7">
                      <Link className="card-text text-decoration-none sec_card_text ">
                        <p>View Details 🡪</p>
                      </Link>
                    </div>
                    <div className="col-5">
                      <p>700.000 L.E</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-6 ">
              <div className="card mx-auto">
                <img src={img_test} className="card-img-top" alt="..." />
                <div className="card-body pb-0">
                  <h5 className="card-title">Sunrise Apartment</h5>
                  <div className="row mt-4">
                    <div className="col-7">
                      <Link className="card-text text-decoration-none sec_card_text ">
                        <p>View Details 🡪</p>
                      </Link>
                    </div>
                    <div className="col-5">
                      <p>700.000 L.E</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="m-78 pt-5 mb-5">
        <div className=" container-fluid row w-100 text_center_media">
          <div className="col-xxl-3 col-xl-3  col-lg-3 col-md-5 col-5  display_hide ">
            <img src={sec4_img} alt="" className="w-100 " />
          </div>
          <div className="col-xxl-8 col-xl-8  col-lg-8 col-md-12 mt-3 ms-3  ">
            <h2 className="text_center_media">{t("Who We Are")}</h2>
            <h3 className="sec4_text_color fw-bold">
              {t("We are keen to provide")} <br />
              {t(
                "All real estate advisory services to support our clients who wish to own residential or administrative properties"
              )}
            </h3>
            <p className="sec4_fontsize mt-4">
              {t(
                "We are distinguished by our unique imprint, especially in the field of real estate marketing."
              )}
              <br />
              {t(
                "This is based on a highly qualified and experienced work team over 9 years"
              )}
              <br />
              {t(
                "A precedent for achieving the best investment opportunities for buyers."
              )}
            </p>
          </div>
        </div>
      </section>
      <section className="container-fluid pt-5">
        <div className="">
          <div className=" row justify-content-center home_cards ">
            <div className="col-12 text-center">
              <h2> {t("Integrated services")}</h2>
            </div>
            <div className="col-xxl-4  col-xl-4 col-lg-6 col-md-6 col-12 mt-5 sec5_hight mb-5">
              <div className="card   mx-auto">
                <div className="card-body text-center ">
                  <div className="sec5_icons d-flex justify-content-center ">
                    <i className=" text-white ">
                      <IoMegaphoneOutline />
                    </i>
                  </div>
                  <h4 className="card-subtitle mb-2 text-body-secondary mt-5">
                    {t("Study of the real estate market")}
                  </h4>

                  <p className="card-text mt-5 pb-4  ">
                    {t(
                      "Our consultancy is supported by extensive research and analysis including comprehensive market research studies, market entry consulting, SWOT analysis, top and best use studies, design reviews and recommendations, facility management plans, and marketing and sales plans. Evaluation and feasibility studies."
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4  col-xl-4 col-lg-6 col-md-6 col-12 mt-5 sec5_hight mb-5">
              <div className="card   mx-auto">
                <div className="card-body text-center ">
                  <div className="sec5_icons d-flex justify-content-center ">
                    <i className=" text-white text-bg-white ">
                      <TbServerCog className="" />
                    </i>
                  </div>
                  <h4 className="card-subtitle mb-2 text-body-secondary mt-5">
                    {t("Consultations for establishing commercial projects")}
                  </h4>

                  <p className="card-text mt-5 pb-4 ">
                    {t(
                      "we help you choose the appropriate location and unit to establish your new project in various fields and commercial sectors in the best geographical locations according to the type of your project, while providing strategic feasibility studies to ensure the success of the project."
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4  col-xl-4 col-lg-6 col-md-6 col-12 mt-5 sec5_hight mb-5">
              <div className="card mx-auto  ">
                <div className="card-body text-center">
                  <div className="sec5_icons d-flex justify-content-center ">
                    <i className=" text-white ">
                      <TbHomeStats />
                    </i>
                  </div>
                  <h4 className="card-subtitle mb-2 text-body-secondary mt-5 font1">
                    {t("REAL ESTATE INVESTMENT")}
                  </h4>
                  <p className="card-text mt-5 font1 ">
                    {t(
                      "We provide comprehensive real estate investment services and provide access to a wide range of investment opportunities in the local market. Our investment experts are committed to providing high-level solutions that maximize returns on your investments, whether you are buying or selling. Thanks to our growing number of investors, we are able to seize exciting investment opportunities as they arise."
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <Link onClick={() => window.scrollTo(0, 0)} to={"/Services"}>
                <button className="sec5_button py-2 px-4 text-white">
                  {t("See More →")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className=" m-78 mt-5">
        <div className="  container-fluid  row ">
          <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-12 mb-4 ">
            <h3 className="">{t("Mechanism of Action")} </h3>
            <div className="  mt-5 sec6_home_text ">
              {/* <div className="sec6_text  me-3 mt-2">
                <div className=" sec6_line  "></div>
              </div> */}
              <li>
                <span>{t("Study of multiple project sites")}</span>
              </li>
              <li className="mt-3">
                <span> {t("Determine the best units in the project")}</span>
              </li>
              <li className="mt-3">
                <span>
                  {" "}
                  {t("Determine the appropriate purpose for purchasing")}
                </span>
              </li>
              <li className="mt-3">
                <span>
                  {" "}
                  {t("Determine the optimal investment in purchasing")}
                </span>
              </li>
            </div>
          </div>

          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-12 display_hide">
            <img src={sec6_img} alt="" className="w-100" />
          </div>
        </div>
      </section>
      <section className="container-fluid sec-7_bg   mt-5 pb-5  h-100">
        <div className="m-78">
          <div className="row  h-100   pt-5">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 text-white text_center_media mt-5  ">
              <h4 className="pt-4">{t("Don't hesitate to ask")}</h4>
              <h3 className="mt-3">
                {t("Free consultation for decision making")}
              </h3>
              <h3 className="mt-2">
                {t("appropriate for your investment opportunity")}
              </h3>
              <h3 className="mt-2">
                {t("Unprecedented levels of credibility in service")}
              </h3>
            </div>
            <form
              className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 mt-5"
              onSubmit={itemsubmit}
            >
              <div className="text-center">
                <input
                  type="text"
                  onChange={getContactinfo}
                  name="name"
                  placeholder={t("Name")}
                  className="sec7_input ps-3 text-white me-3"
                />
                <input
                  type="text"
                  onChange={getContactinfo}
                  name="phoneNumber"
                  placeholder={t("Phone Number")}
                  className="sec7_input mt-4 ps-3 text-white me-3"
                />
                <input
                  type="text"
                  onChange={getContactinfo}
                  name="email"
                  placeholder={t("Email")}
                  className="sec7_input mt-4 ps-3 text-white me-3"
                />
                <input
                  type="text"
                  onChange={getContactinfo}
                  name="message"
                  placeholder={t("Message")}
                  className="sec7_input_message mt-4 ps-3 text-white me-3"
                />
              </div>
              <div className="text-center mt-5">
                <button type="submit" className="sec7_btn px-4 py-1 mb-5">
                  {t("Send")} ➢
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <ToastContainer />
      <section>
        <Footer />
      </section>
    </>
  );
}
