import NavBar from "../../component/Navebar/Navbar";
import "./Services.css";
import { IoMegaphoneOutline } from "react-icons/io5";
import { TbServerCog } from "react-icons/tb";
import { TbHomeStats } from "react-icons/tb";
import { BsClipboardPulse } from "react-icons/bs";
import Footer from "../../component/footer/Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function Services() {
  const { t } = useTranslation();
  return (
    <>
      <section>
        <NavBar />
      </section>
      <section className="container-fluid  services_bg">
        <div className="row text-white justify-content-center align-items-center h-100">
          <div className="col-8 text-center">
            <h1 className=" services_fs">{t("Learn about our services")}</h1>
            <h4>
              {t(
                "We follow best practices with close adherence to quality standards to provide cost-effective solutions, and we work"
              )}
              <br />
              {t("as one team to achieve the desired results.")}
            </h4>
          </div>
        </div>
      </section>
      <section className=" container-fluid mt-5">
        <div className="row  justify-content-center service_cards">
          <div className="col-xxl-6  col-xl-6 col-lg-12 col-12 pt-5  ">
            <div className="card services_cards mx-auto ">
            <div className="card-body text-center ">
                  <div className="sec5_icons d-flex justify-content-center ">
                    <i className=" text-white ">
                      <IoMegaphoneOutline />
                    </i>
                  </div>
                  <h4 className="card-subtitle mb-2 text-body-secondary mt-5 fw-bold">
                  {t("Study of the real estate market")}
                </h4>
               
                <h5 className="card-text mt-5 pb-4 ">
                  {t(
                    "Our consultancy is supported by extensive research and analysis including comprehensive market research studies, market entry consulting, SWOT analysis, top and best use studies, design reviews and recommendations, facility management plans, and marketing and sales plans. Evaluation and feasibility studies."
                  )}
                </h5>
                </div>
            </div>
          </div>
          <div className="col-xxl-6  col-xl-6 col-lg-12  col-12 pt-5  ">
            <div className="card  services_cards mx-auto ">
            <div className="card-body text-center ">
                  <div className="sec5_icons d-flex justify-content-center ">
                    <i className=" text-white text-bg-white ">
                      <TbServerCog className="" />
                    </i>
                  </div>
                  <h4 className="card-subtitle mb-2 text-body-secondary mt-5 fw-bold">
                  {t("Consultations for establishing commercial projects")}
                </h4>

                <h5 className="card-text mt-5 pb-4 ">
                  {t(
                    "we help you choose the appropriate location and unit to establish your new project in various fields and commercial sectors in the best geographical locations according to the type of your project, while providing strategic feasibility studies to ensure the success of the project."
                  )}
                </h5>
                </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 pb-5  justify-content-center service_cards">
          <div className="col-xxl-6  col-xl-6 col-lg-12 col-12 pt-5  ">
            <div className="card services_cards mx-auto ">
            <div className="card-body text-center">
                  <div className="sec5_icons d-flex justify-content-center ">
                    <i className=" text-white ">
                      <TbHomeStats />
                    </i>
                  </div>
                  <h4 className="card-subtitle mb-2 text-body-secondary mt-5 fw-bold">
                  {t("REAL ESTATE INVESTMENT")}
                </h4>
                <h5 className="card-text mt-5 ">
                  {t(
                    "We provide comprehensive real estate investment services and provide access to a wide range of investment opportunities in the local market. Our investment experts are committed to providing high-level solutions that maximize returns on your investments, whether you are buying or selling. Thanks to our growing number of investors, we are able to seize exciting investment opportunities as they arise."
                  )}
                </h5>
                </div>
            </div>
          </div>
          <div className="col-xxl-6  col-xl-6 col-lg-6  col-12 pt-5  ">
            <div className="card services_cards mx-auto ">
              <div className="card-body text-center ">
                <div className="sec5_icons d-flex justify-content-center ">
                  <i className=" text-white ">
                    <BsClipboardPulse />
                  </i>
                </div>
                <h4 className="card-subtitle mb-2 text-body-secondary mt-5 fw-bold">
                  {t("ASSET VALUATION")}
                </h4>
                <h5 className="card-text mt-5 ">
                  {t(
                    "Obtain dependable and precise valuations of your assets or portfolios in any sector across Egypt, provided by experienced and certified professionals who specialize in all types of assets."
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-8 mt-5 text-center">
            <h2 className="pt-5">{t("Get your free consultation")}</h2>
            <Link
              to={"/ContactUs"}
              onClick={() => {
                window.scroll(0, 0);
              }}
              className="text-decoration-none "
            >
              <button className="Servies_btn py-1 px-3 mt-4">
                {t("Contact US")}
              </button>
            </Link>
          </div>
        </div>
      </section>
      <section className="mt-5">
        <Footer />
      </section>
    </>
  );
}
