import { useState, useEffect } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
export const AxiosGetAllByType = () => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const GetAllByType = async (propertyType, PageNum) => {
    setError(null);
    setIsPending(true);
    try {
      let { data } = await axios.get(
        `/ads/all?page=${PageNum}&propertyType=${propertyType}`
      );
      setIsPending(false);
      setData(data);
      //setError(null);
    } catch (err) {
      setIsPending(false);
      setData(null);
      setError("could not fetch the data");
    }
  };
  useEffect(() => {}, []);

  return { data, isPending, error, GetAllByType };
};
export const AxiosGetAllByDeveloper = () => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const GetAllByDeveloper = async (investor) => {
    setError(null);
    setIsPending(true);
    try {
      let { data } = await axios.get(
        `/ads/all?investor=${investor}`
      );
      setIsPending(false);
      setData(data);
      //setError(null);
    } catch (err) {
      setIsPending(false);
      setData(null);
      setError("could not fetch the data");
    }
  };
  useEffect(() => {}, []);

  return { data, isPending, error, GetAllByDeveloper };
};
export const AxiosGetAllByID = () => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const getAllDataByID = async (ID) => {
    setError(null);
    setIsPending(true);
    try {
      let { data } = await axios.get(`/ads/ad/${ID}`);
      setIsPending(false);
      setData(data);
      //setError(null);
    } catch (err) {
      setIsPending(false);
      setData(null);
      setError("could not fetch the data");
    }
  };
  useEffect(() => {}, []);

  return { data, isPending, error, getAllDataByID };
};
export const AxiosGetDataBySearch = () => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const GetDataBySearch = async (investor,Location, Maxprice, Type, PageNum) => {
    setError(null);
    setIsPending(true);
    try {
      let { data } = await axios.get(
        `ads/all?investor=${investor}&location=${Location}&maxPrice=${Maxprice}&propertyType=${Type}&page=${PageNum}`
      );
      setIsPending(false);
      setData(data);
      //setError(null);
    } catch (err) {
      setIsPending(false);
      setData(null);
      setError("could not fetch the data");
    }
  };
  useEffect(() => {}, []);

  return { data, isPending, error, GetDataBySearch };
};
export function AxiosGetRecomende() {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const GetRecomende = async (Id,Location,Price, propertyType) => {
    setError(null);
    setIsPending(true);
    try {
      let { data } = await axios.get(
        `ads/recommender?location=${Location}&propertyType=${propertyType}&price=${Price}&mainAdId=${Id}`,
        {}
      );
      setIsPending(false);
      setData(data);
      //setError(null);
    } catch (err) {
      setIsPending(false);
      setData(null);
      setError("could not fetch the data");
    }
  };

  return { data, isPending, error, GetRecomende };
}
export function AxiosGetGroupBYID() {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const GetONEGroupData = async (id) => {
    setError(null);
    setIsPending(true);
    try {
      let { data } = await axios.get(`/api/v1/group/${id}`, {
        headers: {},
      });
      setIsPending(false);
      setData(data);
      //setError(null);
    } catch (err) {
      setIsPending(false);
      setData(null);
      setError("could not fetch the data");
    }
  };

  return { data, isPending, error, GetONEGroupData };
}
export const AxiosGetPeriodBYID = (formData) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const getPeridONEData = async (GroupID) => {
    setError(null);
    setIsPending(true);
    try {
      let { data } = await axios.get(`/api/v1/period/${GroupID}`, formData, {});
      setIsPending(false);
      setData(data);
      //setError(null);
    } catch (err) {
      setIsPending(false);
      setData(null);
      setError("could not fetch the data");
    }
  };
  useEffect(() => {}, []);

  return { data, isPending, error, getPeridONEData };
};
export const AxiosGetStudentBYID = () => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const getStudentONEData = async (StudentID) => {
    setError(null);
    setIsPending(true);
    try {
      let { data } = await axios.get(`/api/v1/student/${StudentID}`);
      setIsPending(false);
      setData(data);
      //setError(null);
    } catch (err) {
      setIsPending(false);
      setData(null);
      setError("could not fetch the data");
    }
  };
  useEffect(() => {}, []);

  return { data, isPending, error, getStudentONEData };
};
export const AxiosGetExelstudents = () => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const getExelStudent = async () => {
    setError(null);
    setIsPending(true);
    try {
      // let { data } = await axios.get(`/api/v1/student/export/excel`, {
      //   responseType: "blob",
      // });
      // setIsPending(false);
      // setData(data);
      // saveAs(data, "students.xlsx");
      // setError(null);
      fetch(
        "https://api-center.ebdaa-business.com/api/v1/student/export/excel",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
        }
      )
        .then()
        .catch((e) => e);
    } catch (err) {
      setIsPending(false);
      setData(null);
      setError("could not fetch the data");
    }
  };
  useEffect(() => {}, []);

  return { data, isPending, error, getExelStudent };
};
// export const AxiosGetTeacherSearch = () => {
//   const [data, setData] = useState(null);
//   const [isPending, setIsPending] = useState(false);
//   const [error, setError] = useState(null);
//   const getDataTeacherSearch = async (Search) => {
//     setError(null);
//     setIsPending(true);
//     try {
//       let { data } = await axios.get(`/api/teachers/filter?keyword=${Search}`, {
//         headers: {
//           "ngrok-skip-browser-warning": "true",
//         },
//       });
//       setIsPending(false);
//       setData(data);
//       //setError(null);
//     } catch (err) {
//       setIsPending(false);
//       setData(null);
//       setError("could not fetch the data");
//     }
//   };
//   useEffect(() => {}, []);

//   return { data, isPending, error, getDataTeacherSearch };
// };
export const AxiosGetAttendTabel = () => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const GetAttendTabel = async (GroupId, PageNum) => {
    setError(null);
    setIsPending(true);
    try {
      let { data } = await axios.get(
        `/api/v1/student/group/${GroupId}/page?pageNumber=${PageNum}&pageSize=10&sortDir=ASC`,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      setIsPending(false);
      setData(data);
      //setError(null);
    } catch (err) {
      setIsPending(false);
      setData(null);
      setError("could not fetch the data");
    }
  };
  useEffect(() => {}, []);

  return { data, isPending, error, GetAttendTabel };
};
export const AxiosGetAttendSearch = (GroupId, PageNum) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const GetAttendSearch = async (Word) => {
    setError(null);
    setIsPending(true);
    try {
      let { data } = await axios.get(
        `/api/v1/student/group/${GroupId}/page?keyword=${Word}&pageNumber=${PageNum}&pageSize=10&sortDir=ASC`,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      setIsPending(false);
      setData(data);
      //setError(null);
    } catch (err) {
      setIsPending(false);
      setData(null);
      setError("could not fetch the data");
    }
  };
  useEffect(() => {}, []);

  return { data, isPending, error, GetAttendSearch };
};
export const AxiosGetProfile = (PageNum) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const GetProfile = async (Word, id) => {
    setError(null);
    setIsPending(true);
    try {
      let { data } = await axios.get(
        `/api/v1/notice/student/${id}?keyword=${Word}&pageNumber=${PageNum}&pageSize=10`,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      setIsPending(false);
      setData(data);
      //setError(null);
    } catch (err) {
      setIsPending(false);
      setData(null);
      setError("could not fetch the data");
    }
  };
  useEffect(() => {}, []);

  return { data, isPending, error, GetProfile };
};
