import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineLanguage } from "react-icons/md";
import { Link } from "react-router-dom";
import { UserContext } from "../../UserContext";

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [user, setUser] = useContext(UserContext);
  const [lang, setLang] = useState(localStorage.getItem("language") || "en");

  useEffect(() => {
    i18n.changeLanguage(lang);
    setUser(lang);
    document.documentElement.lang = lang;
    localStorage.setItem("language", lang);
  }, [lang, i18n, setUser]);

  const toggleLanguage = () => {
    const newLanguage = lang === "ar" ? "en" : "ar";
    setLang(newLanguage);
  };

  return (
    <>
      <Link className="languag_btn text-decoration-none" onClick={toggleLanguage}>
        {lang === "en" ? "العربية" : "English"}
      </Link>
      {/* <button>
        <MdOutlineLanguage />
      </button>
      <button>
        <MdOutlineLanguage />
      </button> */}
    </>
  );
}

export default LanguageSelector;