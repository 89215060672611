import NavBar from "../../component/Navebar/Navbar";
import "../../pages/Buy/Buy.css";
import Footer from "../../component/footer/Footer";
import { Link, useParams } from "react-router-dom";
import Paginate from "../../component/pagination/Paginate";
import { useState } from "react";
import Properities from "../../component/properities/Properities";
import { useTranslation } from "react-i18next";
import { AxiosGetDataBySearch } from "../../component/axios/GetApi/GetApi";
import { useEffect } from "react";
export default function Search() {
  const { '*': path } = useParams();

  const searchParams = path ? path.split('/') : [];

  const investor = searchParams[0] || "";
  const Location = searchParams[1] || "";
  const Type = searchParams[2] || "";
  const Maxprice = searchParams[3] || "";
  let [currentpageNum, setcurrentpageNum] = useState(1);
  const { data, GetDataBySearch } = AxiosGetDataBySearch();
  let SearchData = data ? data.data : [];
  let totalpageNum = data ? data.pagination.totalPages : 1;
  const paginate = (pageNumber) => {
    if ("" === 0) {
      setcurrentpageNum(1);
    } else {
      setcurrentpageNum(pageNumber);
    }
  };
  const { t } = useTranslation();
  useEffect(() => {
    GetDataBySearch(investor,Location, Maxprice, Type, currentpageNum);
  }, [investor,Location, Maxprice, Type, currentpageNum]);
  return (
    <>
      <section>
        <NavBar />
      </section>
      <Properities />
      <section className="container-fluid">
        <div className="container-fluid ">
          <h2>{t("Search Result")}</h2>
          {SearchData.length > 0 ? (
            <div className="row justify-content-center mt-5 home_sell_card">
              {SearchData.map((data, index) => (
              <div
              key={index}
              className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-6 mb-4 "
            >
              <div className="card mx-auto">
                <Link to={`/Details/${data._id}`} onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                  <img src={data.coverImage} className="img_card" alt="..." />
                </Link>
                <div className="card-body pb-0">
                  <h5 className="card-title">{data.title}</h5>
                  <div className="row mt-4">
                    <div className=" col-12 text_center_media">
                      <p>
                        {t("Price: ")}
                        {data.price} {t("L.E")}
                      </p>
                    </div>
                    <div className=" text-center">
                      <Link
                        to={`/Details/${data._id}`}
                        onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' })}}
                        className="card-text text-decoration-none sec_card_text  "
                      >
                        <p>{t("View Details →")}</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              ))}
            </div>
          ) : (
            <div className="text-center">
              <h1>{t("No Result")}</h1>
              <div className="text-center mt-3">
                <button className="sec7_btn px-4 py-1 mb-5">
                  {t("Make Your Demand")} ➢
                </button>
              </div>
            </div>
          )}

          <div className="mt-3">
            <Paginate paginate={paginate} totalpageNum={totalpageNum} />
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </>
  );
}
