import NavBar from "../../component/Navebar/Navbar";
import "./AboutUs.css";
import About1 from "../../photos/about1.png";
import About2 from "../../photos/about2.png";
import Footer from "../../component/footer/Footer";
import { useTranslation } from "react-i18next";
export default function AboutUs() {
  const { t } = useTranslation();
  return (
    <>
      <section>
        <NavBar />
      </section>
      <section className="container-fluid About_bg  ">
        <div className="row text-white justify-content-center align-items-center h-100">
          <div className="col-8 text-center ">
            <h1 className=" About_fs">{t("Who We Are?")}</h1>
            <h4 className="">
              {t(
                "A highly qualified work team using both"
              )}
              <br />
              {t("Techniques and methods in real estate marketing")}
            </h4>
          </div>
        </div>
      </section>
      <section className=" m-78">
        <div className="row pt-5">
          <div className="col-xxl-4 col-xl-4 col-lg-12 col-12 d-flex justify-content-center">
            <img src={About1} alt="" className="about_img pt-5 about_hidden" />
          </div>
          <div className="col-xxl-7 col-xl-7 col-lg-12 col-12">
            <div className="about_text1 ">
              <h1 className="">{t("Our vision")}</h1>
              <div className="col-xxl-4 col-xl-4 col-lg-12 col-12 d-flex justify-content-center">
                <img
                  src={About1}
                  alt=""
                  className="about_img pt-3 about_show mb-3"
                />
              </div>
              <h6 className="fw-bold ">
                {t(
                  "We seeks to strengthen its leadership in the Egyptian real estate market by providing distinguished and sustainable investment experiences, The company is also keen to achieve the highest levels of financial and investment returns for our clients and achieve excellence in all aspects (residential - administrative - commercial), Therefore, we strive to provide real estate solutions that meet market needs and contribute to sustainable development in accordance with Egypt’s Vision (2030), This is done by relying on the best specialists in the real estate field and promoting and innovating distinct real estate methods and strategies thanks to our innovations and technical development. We are also keen to enhance our customers’ confidence in our vision and diverse services, And establishing strong partnerships that enhance our position as a reliable and innovative company in the world of Egyptian real estate investment."
                )}

              </h6>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 mb-5 m-78">
        <div className="row pt-5">
          <div className="col-xxl-7 col-xl-7 col-lg-12 col-12 mb-4">
            <div className="about_text ">
              <h1 className="">{t("Our goals")}</h1>
              <div className="col-xxl-4 col-xl-4 col-lg-12 col-12 d-flex justify-content-center mb-3">
                <img src={About2} alt="" className="about_img  about_show" />
              </div>
              <h6 className=" fw-bold">
                {t(
                  "Achieving market leadership and sustainability,"
                )}
                <br />
                {t(
                  "focusing on profitability and social responsibility, building strong relationships with customers and partners, and using technology to enhance efficiency, achieve growth,"
                )}
                  <br />
                {t(
                  "and increase investment returns for beneficiaries of our multiple services."
                )}
              </h6>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-lg-12 col-12 d-flex justify-content-center mb-5">
            <img src={About2} alt="" className="about_img  about_hidden" />
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </>
  );
}
