import NavBar from "../../component/Navebar/Navbar";
import "./Sell.css";
import Footer from "../../component/footer/Footer";
import Properities from "../../component/properities/Properities";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import React from "react";
import { UseAxiosPostDemandandSell } from "../../component/axios/PostApi/PostApi";
export default function Sell() {
  const section4Ref = useRef(null);


  const { t } = useTranslation();
  // const [images, setImages] = useState([]);
  // const maxNumber = 4;
  // const formData = new FormData();
  // const onChange = (imageList, addUpdateIndex) => {
  //   console.log(imageList, addUpdateIndex);
  //   setImages(imageList);
  // };

  const [Sell, setSell] = useState({
    email: "",
    name: "",
    phoneNumber: "",
    message: "",
    propertyType: "",
    avgPrice: "",
    location: "",
    purpose: "Sell",
  });
  function getSellinfo(e) {
    let myitem = { ...Sell };
    myitem[e.target.name] = e.target.value;
    setSell(myitem);
  }
  // const fileArray = images.map((image) => image.file);
  // fileArray.forEach((file) => {
  //   formData.append("image", file);
  // });
  // formData.append("email", Sell.email);
  // formData.append("name", Sell.name);
  // formData.append("phoneNumber", Sell.phoneNumber);
  // formData.append("message", Sell.message);
  // formData.append("propertyType", Sell.propertyType);
  // formData.append("avgPrice", Sell.avgPrice);
  // formData.append("location", Sell.location);
  // formData.append("purpose", Sell.purpose);
  // for (var pair of formData.entries()) {
  //   console.log(pair[1] + ", " + pair[0]);
  // }
  const { HandelPostApi } = UseAxiosPostDemandandSell(Sell);
  const itemsubmit = (e) => {
    e.preventDefault();
    HandelPostApi();
  };

  return (
    <>
      <section>
        <NavBar />
      </section>
      <Properities section4Ref={section4Ref} />
      <section ref={section4Ref} className="container-fluid   sec2_sell_bg pt-5 pb-5 text-white">
        <div className=" m-78">
          <form onSubmit={itemsubmit}>
            <div className="row mt-5 justify-content-center ">
              <h2 className=" ">{t("Enter details For Sell")}</h2>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-12  mt-4">
                <div className="text-center">
                  <input
                    type="text"
                    onChange={getSellinfo}
                    name="name"
                    placeholder={t("Name")}
                    className="sec2-sell_input ps-3 text-white me-3 d-block w-100"
                  />
                  <input
                    type="text"
                    onChange={getSellinfo}
                    name="email"
                    placeholder={t("Email")}
                    className="sec2-sell_input mt-4 ps-3 text-white me-3 d-block w-100"
                  />
                  <input
                    type="text"
                    onChange={getSellinfo}
                    name="propertyType"
                    placeholder={t("Project/ Type")}
                    className="sec2-sell_input mt-4 ps-3 text-white me-3 d-block w-100"
                  />
                </div>
              </div>
              <div className="col-1"></div>
              <div className=" col-xxl-4 col-xl-4 col-lg-4 col-12  mt-4">
                <div className="text-center">
                  <input
                    type="text"
                    name="phoneNumber"
                    onChange={getSellinfo}
                    placeholder={t("Phone Number")}
                    className="sec2-sell_input ps-3 text-white me-3 d-block w-100"
                  />
                  <input
                    type="text"
                    name="location"
                    onChange={getSellinfo}
                    placeholder={t("Location")}
                    className="sec2-sell_input mt-4 ps-3 text-white me-3 d-block w-100"
                  />
                  <input
                    type="text"
                    name="avgPrice"
                    onChange={getSellinfo}
                    placeholder={t("Average Price")}
                    className="sec2-sell_input mt-4 ps-3 text-white me-3 d-block w-100"
                  />

                  {/* <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    <button
                      style={isDragging ? { color: "red" } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop here the Img
                    </button>
                    &nbsp;
                    <button onClick={onImageRemoveAll}>
                      Remove all images
                    </button>
                    <div className="row">
                      {imageList.map((image, index) => (
                        <div
                          key={index}
                          className="image-item col-xxl-3 col-xl-3 col-lg-3 col-12 "
                        >
                          <img
                            src={image["data_url"]}
                            alt=""
                            width="100"
                            className="mt-3"
                          />
                          <div className="image-item__btn-wrapper ">
                            <button onClick={() => onImageUpdate(index)}>
                              Update
                            </button>
                            <button onClick={() => onImageRemove(index)}>
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </ImageUploading> */}
                </div>
              </div>
              <div className="col-xxl-9 col-xl-9 col-lg-9 col-12">
                <input
                  type="text"
                  name="message"
                  onChange={getSellinfo}
                  placeholder={t("Message")}
                  className="sec2-sell_input_mass mt-4 ps-3 text-white me-3 d-block w-100 "
                />
              </div>
              <div className="text-center mt-5 pb-5">
                <button type="submit" className="sec7_btn px-4 py-1">
                  {t("Send ➢")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </>
  );
}
