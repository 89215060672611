import { Link } from "react-router-dom";
import "./NavbarHome.css";
import { useState } from "react";
import Logo from "../../photos/PROKE-LOGO/full-logo/digital-rgb/PROKER-RGB-svg 1.png";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSwitcher/LanguageSwitcher";
import { BsLayoutTextSidebar } from "react-icons/bs";

// import Navicon from "../../photos/nav-icon.png";

export default function NavbarHome() {
  const [button1, setbutton1] = useState(false);
  const [button2, setbutton2] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <section className="text_white m-nav-78 overflow-hidden  ">
        <nav className="navbar navbar-expand-lg py-2  ">
          <div className="container-fluid text_color_media">
            <Link to={"/"}  onClick={() => window.scrollTo(0, 0)}>
              <img src={Logo} alt="" className="logo_home" />
            </Link>
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation "
            >
              <span className=" NavHome_btn fs-2">
                <BsLayoutTextSidebar />
              </span>
            </button>
            <div className="collapse navbar-collapse  " id="navbarNav">
              <div
                className=" w-100 NaveHome_media ms-9 "
               
              >
            
                    <Link
                      to={"/"}
                      className="NavHome_Link text-decoration-none m-4 NaveHome_media"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("Home")}
                    </Link>
                 
                    <Link
                      to={"/properities/Buy"}
                      className="NavHome_Link text-decoration-none m-4 NaveHome_media"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("Properties")}
                    </Link>
                 
                    <Link
                      to={"/AboutUs"}
                      className="NavHome_Link  text-decoration-none m-4 NaveHome_media"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("About")}
                    </Link>
                
                    <Link
                      to={"/Services"}
                      className=" NavHome_Link  text-decoration-none m-4 NaveHome_media"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("Services")}
                    </Link>
                 
                    <Link
                      to={"/ContactUs"}
                      className="NavHome_Link  text-decoration-none m-4 NaveHome_media"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("Contact us")}
                    </Link>
                    <Link
                     to={"#"}
                      className="NavHome_Link ms-4 text-decoration-none  mt-4 NaveHome_media"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                     <LanguageSelector   />
                    </Link>
                   
                
              </div>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
}

{/* <nav class="navbar navbar-expand-lg ">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Navbar</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" aria-disabled="true">Disabled</a>
        </li>
      </ul>
      <form class="d-flex" role="search">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form>
    </div>
  </div>
</nav> */}