import NavBar from "../../component/Navebar/Navbar";
import "./ContactUs.css";
import Footer from "../../component/footer/Footer";
import { useTranslation } from "react-i18next";
import { UseAxiosPostContactUs } from "../../component/axios/PostApi/PostApi";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ContactUs() {
  const { t } = useTranslation();
  const [ContactUs, setContactUs] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    message: "",
  });
  function getContactinfo(e) {
    let contactinfo = { ...ContactUs };
    contactinfo[e.target.name] = e.target.value;
    setContactUs(contactinfo);
  }
  const { HandelPostApi } = UseAxiosPostContactUs(ContactUs);
  const itemsubmit = (e) => {
    e.preventDefault();
    HandelPostApi();
  };
  return (
    <>
      <section>
        <NavBar />
      </section>
      <section className="container-fluid ContactUs_bg ">
        <div className="row text-white justify-content-center align-items-center h-100">
          <div className="col-8 text-center">
            <h1 className=" ContactUs_fs">{t("Contact Us")}</h1>
            <h4>{t("Do not hesitate to contact us")}</h4>
          </div>
        </div>
      </section>
      <section className="mt-5 m-78">
        <div className="row pt-5 container-fluid">
          <div className="col-xxl-6 col-xl-6 col-lg-12 col-12 ContactUs_text ContactUs_text_center">
            <h2 className="m-4">{t("Contact Us")}</h2>
            <h4 className="p-2">{t("Don't hesitate to ask")}</h4>
              <h3 className="m-2">
                {t("Free consultation for decision making")}
              </h3>
              <h3 className="m-2">
                {t("appropriate for your investment opportunity")}
              </h3>
              <h3 className="m-2">
                {t("Unprecedented levels of credibility in service")}
              </h3>
          </div>
          <form
            className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 mt-5"
            onSubmit={itemsubmit}
          >
            <div className="text-center">
              <input
                type="text"
                onChange={getContactinfo}
                name="name"
                placeholder={t("Name")}
                className="sec2-contact_input ps-3  me-3"
              />
              <input
                type="text"
                onChange={getContactinfo}
                name="phoneNumber"
                placeholder={t("Phone Number")}
                className="sec2-contact_input mt-4 ps-3  me-3"
              />
              <input
                type="text"
                onChange={getContactinfo}
                name="email"
                placeholder={t("Email")}
                className="sec2-contact_input mt-4 ps-3  me-3"
              />
              <input
                type="text"
                onChange={getContactinfo}
                name="message"
                placeholder={t("Message")}
                className="sec2-contact_input_mass mt-4 ps-3  me-3"
              />
            </div>
            <div className="text-center mt-5">
              <button type="submit" className="sec7_btn px-4 py-1 mb-5">
                {t("Send")} ➢
              </button>
            </div>
          </form>
        </div>
      </section>
      <ToastContainer />
      <section>
        <Footer />
      </section>
    </>
  );
}
