import { Link } from "react-router-dom";
import "./Navbar.css";
import { useState } from "react";
import Logo from "../../photos/PROKE-LOGO/full-logo/digital-rgb/PROKER-RGB-png.png";
import LanguageSelector from "../LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { BsLayoutTextSidebar } from "react-icons/bs";

export default function NavBar() {
  const [button1, setbutton1] = useState(false);
  const [button2, setbutton2] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <section className=" navbar_bg overflow-hidden   ">
        <div className="m-nav-78">
          <nav className="navbar navbar-expand-lg py-2  ">
            <div className="container-fluid">
              <Link to={"/"}  onClick={() => window.scrollTo(0, 0)}>
                <img src={Logo} alt="" className="logo_home" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="fs-2">
                  <BsLayoutTextSidebar />
                </span>
              </button>
              <div className="collapse navbar-collapse  " id="navbarNav">
              <div
                className=" w-100 NaveHome_media ms-9 "
               
              >
            
                    <Link
                    
                      to={"/"}
                      className="Nav_Link text-decoration-none m-4 NaveHome_media"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("Home")}
                    </Link>
                 
                    <Link
                      to={"/properities/Buy"}
                      className="Nav_Link text-decoration-none m-4 NaveHome_media"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("Properties")}
                    </Link>
                 
                    <Link
                      to={"/AboutUs"}
                      className="Nav_Link  text-decoration-none m-4 NaveHome_media"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("About")}
                    </Link>
                
                    <Link
                      to={"/Services"}
                      className=" Nav_Link  text-decoration-none m-4 NaveHome_media"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("Services")}
                    </Link>
                 
                    <Link
                      to={"/ContactUs"}
                      className="Nav_Link  text-decoration-none m-4 NaveHome_media"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {t("Contact us")}
                    </Link>
                    <Link
                      to={"#"}
                     className="NavHome_Link ms-4 text-decoration-none  mt-4 NaveHome_media"
                     onClick={() => window.scrollTo(0, 0)}
                   >
                    <LanguageSelector   />
                   </Link>
                   
                
              </div>
            </div>
            </div>
          </nav>
        </div>
      </section>
    </>
  );
}
