import { Link } from "react-router-dom";
import "./Properities.css";
import sec1_img from "../../photos/Group 1.png";
import { useTranslation } from "react-i18next";

import { useRef } from "react";

export default function Properities({section4Ref,section3Ref,section5Ref}) {

  const { t } = useTranslation();
  

  return (
    <>
      <section className=" m-78 h-100 mt-5 mb-5 pt-5">
        <div className="row container-fluid align-items-center justify-content-center h-100">
          <div className="col-xxl-5 col-xl-5 col-lg-5  col-12  text_properities_center_media">
            <h1 className="sec1_properities_text pt-5">
              {t("Featured Properties")}
            </h1>
            <p>
              {t(
                "A unique imprint, especially in commercial real estate marketing"
              )}
            </p>
            <div className="mt-4 ">
              <Link    to={"/properities/Buy"} className="text-decoration-none "   >
                <button className="sec1_properities_btn me-2"> 
                  {t("Buy")}
                </button>
              </Link>
              <Link   to={"/properities/Sell"} className="text-decoration-none  " >
                <button className="sec1_properities_btn me-2 mb-3">
                  {t("Sell")}
                </button>
              </Link>
              <Link
              
                to={"/properities/Demand"}
                className="text-decoration-none "
              >
                <button className="sec1_properities_btn me-2">
                  {t("Demand")}
                </button>
              </Link>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-12 d-flex justify-content-center display_properities_hide ">
            <img
              src={sec1_img}
              alt=""
              className="sec1_properities-img w-100 "
            />
          </div>
        </div>
      </section>
    </>
  );
}
