import NavBar from "../../component/Navebar/Navbar";
import "./Buy.css";
import Footer from "../../component/footer/Footer";
import { Link } from "react-router-dom";
import Paginate from "../../component/pagination/Paginate";
import { useRef, useState } from "react";
import Properities from "../../component/properities/Properities";
import { useTranslation } from "react-i18next";
import { AxiosGetAllByType } from "../../component/axios/GetApi/GetApi";
import { useEffect } from "react";
export default function Buy() {
  const section5Ref = useRef(null);
  const section1Ref = useRef(null);


  const scrollToSection = (sectionRef) => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  let [currentpageNum, setcurrentpageNum] = useState(1);
  let [PropertyType, setPropertyType] = useState("house");
  const { data, GetAllByType } = AxiosGetAllByType();
  let DataByType = data ? data.data : [];

  let totalpageNum = data ? data.pagination.totalPages : 1;
  const paginate = (pageNumber) => {
    if ("" === 0) {
      setcurrentpageNum(1);
    } else {
      setcurrentpageNum(pageNumber);
    }
  };

  const { t } = useTranslation();
  useEffect(() => {
    GetAllByType(PropertyType, "buy", currentpageNum);
  }, [PropertyType, currentpageNum]);
  return (
    <>
      <section>
        <NavBar />
      </section>
      <Properities  section5Ref={section5Ref} />
      <section ref={section5Ref} className="m-78 pt-4 mb6 ">
        <div className="container-fluid ">
        <div className=" row mt-4 justify-content-center ">
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-12 d-flex justify-content-center mt-2 ">
              <Link
                onClick={() => {
                  setPropertyType("house");
                  scrollToSection(section1Ref)
                }}
                className="sec3_filtration text-decoration-none w-100 h-100"
              >
                <div  className="New_Projects_btn me-2 ">
                <button className="btn-sec3">
                {t("New Projects")}
                </button>
                </div>
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-12 mt-2 ">
              <Link
                onClick={() => {
                  setPropertyType("Vila");
                  scrollToSection(section1Ref)
                }}
                className=" text-decoration-none sec3_filtration"
              >
                <div className="vila_btn me-2 ">
                <button className="btn-sec3">
                {t("Vila")}
                </button>
                </div>
              
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-12 mt-2 ">
              <Link
                onClick={() => {
                  setPropertyType("Apartment");
                  scrollToSection(section1Ref)
                }}
                className=" text-decoration-none sec3_filtration"
              >
                  <div className="apartment_btn me-2 ">
                <button className="btn-sec3">
                {t("Apartments")}
                </button>
                </div>
              
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-12 mt-2 ">
              <Link
                onClick={() => {
                  setPropertyType("Office");
                  scrollToSection(section1Ref)
                }}
                className=" text-decoration-none sec3_filtration"
              >
                 <div className="office_btn me-2 ">
                <button className="btn-sec3">
                {t("Office")}
                </button>
                </div>
              
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-12 mt-2 ">
              <Link
                onClick={() => {
                  setPropertyType("Shop");
                  scrollToSection(section1Ref)
                }}
                className=" text-decoration-none sec3_filtration"
              >
                   <div className="shope_btn me-2 ">
                <button className="btn-sec3">
                {t("Shop")}
                </button>
                </div>
              
              </Link>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-6 col-12 mt-2 ">
              <Link
                onClick={() => {
                  setPropertyType("Coastal");
                  scrollToSection(section1Ref)
                }}
                className=" text-decoration-none sec3_filtration"
              >
                   <div className="coastal_btn me-2 ">
                <button className="btn-sec3">
                {t("Coastal")}
                </button>
                </div>
              </Link>
            </div>
          </div>
          <div ref={section1Ref}>
          {DataByType.length > 0 ? (
            <div className="row justify-content-center mt-5 home_sell_card ">
              {DataByType.map((data, index) => (
           <div
           key={index}
           className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-6 mb-4 "
         >
           <div className="card mx-auto">
             <Link to={`/Details/${data._id}`} onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' })}}>
               <img src={data.coverImage} className="img_card" alt="..." />
             </Link>
             <div className="card-body pb-0">
               <h5 className="card-title">{data.title}</h5>
               <div className="row mt-4">
                 <div className=" col-12 text_center_media">
                   <p>
                     {t("Price: ")}
                     {data.price} {t("L.E")}
                   </p>
                 </div>
                 <div className=" text-center">
                   <Link
                     to={`/Details/${data._id}`}
                     onClick={()=>{window.scrollTo({ top: 0, behavior: 'smooth' })}}
                     className="card-text text-decoration-none sec_card_text  "
                   >
                     <p>{t("View Details →")}</p>
                   </Link>
                 </div>
               </div>
             </div>
           </div>
         </div>
              ))}
               <div className="mt-3 d-flex justify-content-center">
        <Paginate paginate={paginate} totalpageNum={totalpageNum} />
      </div>
            </div>
          ) : (
            <div className="text-center mt-4">
              <h1>{t("No Result")} </h1>
              <div className="text-center mt-3">
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/properities/Demand"}
                  className="text-decoration-none"
                >
                  <button className="sec7_btn px-4 py-1 mb-5">
                    {t("Make Your Demand ➢")}
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
        </div>
    
     
      </section>
      <section>
        <Footer />
      </section>
    </>
  );
}
